<template>
  <div style="background-color: var(--emotiva-pink-login); height: calc(100vh - 96px); width: 100%; overflow: hidden;">
    <b-container fluid>
      <b-row>
        <b-col class="p-5 img-col center-left-right">
          <img src="../assets/images/loginImage.png" alt="" class="img-fluid" />
        </b-col>
        <b-col class="center p-5">
          <div v-if="isLogin" style="background-color: #fff" class="p-5 form">
            <h4 class="p-0 mb-4">{{ $t("login") }}</h4>
            <login-form style="width: 400px" :toggleForm="toggleLogin" />
          </div>
          <div v-else style="background-color: #fff" class="p-5 form">
            <register-form style="width: 400px" :toggleForm="toggleLogin" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LoginForm from "./../components/LoginForm";
import RegisterForm from "./../components/RegisterForm";
export default {
  components: {
    LoginForm,
    RegisterForm,
  },
  data() {
    return {
      isLogin: true,
    };
  },
  methods: {
    toggleLogin() {
      this.isLogin = !this.isLogin;
    },
  },
};
</script>

<style scoped>
.tab-container >>> .nav-tabs .nav-link.active {
  border-top: 2px solid var(--primary);
  border-bottom-color: var(--light);
  font-weight: bold;
  background: transparent;
  color: var(--dark) !important;
}

.tab-container >>> .nav-tabs .nav-link {
  color: black;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-left-right{
  display: flex;
  justify-content: center;
}

.form {
  border-radius: 20px;
}

@media (max-width: 1050px) {
  .img-col {
    display: none;
  }
}
</style>
